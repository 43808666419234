import { Button, Input, Select } from "@crowd/ui-kit";
import Validator from "@crowd/ui-kit/build/classes/Validator";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProposalService from "../../../../../services/proposalService";
import { showErrorInfoModal } from "../../../../../store/actions/LayoutActions";
import { ApiStatusCode } from "../../../../../types/Common.interface";
import { ProposalForwardRequest } from "../../../../../types/Proposal.interface";
import ClosingCard from "../../../../presentational/Cards/ClosingCard/ClosingCard";
import { SelectionForwardFormProps } from "./SelectionForwardForm.interface";
import "./SelectionForwardForm.scss";
import { useIsMobile } from "../../../../../utils/hooks/useMedia";
import {
  selectionGetCounts,
  selectionGetIdeas,
  selectionGetTags,
} from "../../../../../store/actions/SelectionActions";
import { selectionExpertsListWithoutCurrentUserSelector } from "../../../../../store/selectors/selection";
import { useSelectionParams } from "../../helpers/params";

const SelectionForwardForm: FC<SelectionForwardFormProps> = ({
  idea,
  onClose,
}) => {
  const isMobile = useIsMobile();
  const { stageId, folder } = useSelectionParams();

  const dispatch = useDispatch();

  const expertsList = useSelector(
    selectionExpertsListWithoutCurrentUserSelector
  );

  const [reason, setReason] = useState("");
  const [reasonValidator, setReasonValidator] = useState<Validator>();
  const [expert, setExpert] =
    useState<typeof expertsList extends(infer R)[] ? R : never>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isValid = () => !!expert && reasonValidator?.isValid();

  const forward = async () => {
    try {
      setIsSubmitting(true);

      const query: ProposalForwardRequest = {
        id: idea.id,
        stageId,
        expertId: expert?.value,
        text: reason,
      };
      const { status, message } = await ProposalService.selectionForward(query);
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }

      setIsSubmitting(false);

      dispatch(selectionGetCounts(stageId));
      dispatch(
        selectionGetIdeas({
          stageId,
          folder,
        })
      );
      dispatch(selectionGetTags(stageId));

      onClose();
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
      setIsSubmitting(false);
    }
  };

  return (
    <ClosingCard
      className="selection-forward-form"
      isOpened
      onClose={onClose}
      type={isMobile ? "gray" : "blue"}
    >
      <label>Укажите причину передачи</label>
      <Input
        value={reason}
        onChange={setReason}
        placeholder="Введите текст"
        isRequired
        shouldValidate
        validateRules={{ notEmpty: true, maxLength: 250 }}
        maxLength={250}
        onInitValidator={setReasonValidator}
      />

      <label>Выберите эксперта для передачи</label>
      <Select
        value={expert}
        items={expertsList}
        onItemSelect={setExpert}
        bindTo="text"
        label="Выберите эксперта"
        restrictTo="258px"
        closeOnSelect
        openSide="bottom"
      />

      <div className="selection-forward-form__buttons">
        <Button
          type="filled"
          text="Передать"
          isDisabled={!isValid()}
          isLoading={isSubmitting}
          onClick={forward}
          size="m"
        />
      </div>
    </ClosingCard>
  );
};

export default SelectionForwardForm;
