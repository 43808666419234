import { API } from "./api";

const baseUrl = "/api/stage";
const baseAdminUrl = "/api/project/stage";

const StageService = {
  getStages: (id?: string) => {
    // Получение этапа по идентификатору. Или списка всех этапов, если идентификатор не передан.
    let url = `${baseUrl}/get`;
    if (id) url += `?id=${id}&showStats=true`;

    return API.get(url).then((data) => data?.data);
  },
  getGenerationStageBySelectionStage: (id: string) =>
    API.get(`${baseUrl}/getGenerationStageBySelectionStage?id=${id}`).then(
      ({ data }) => data
    ),
  getAccessibleIds: () =>
    // Возвращает список идентификаторов этапов, которые доступны авторизованному пользователю.
    API.get(`${baseUrl}/getAccessibleIds`).then(({ data }) => data),
  stop: (id: string) =>
    API.post(`${baseAdminUrl}/stop`, { id }).then(({ data }) => data),
  onStageUsers: (stageId: string, projectId: string) =>
    API.get(`${baseUrl}/onStageUsers`, {
      params: {
        stageId,
        stageType: "expert_selection",
        projectId,
        page: 0,
        size: 100,
      },
    }).then(({ data }) => data),
};

export default StageService;
